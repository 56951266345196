import styled, { css, keyframes } from "styled-components";

const riseUp = keyframes`
    0% {
        transform: translateY(125%);
    }
    100% {
        transform: translateY(0);
    }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Line = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const Sentence = styled.span<{
  notReady: boolean;
  sentenceIndex: number;
}>(
  ({ sentenceIndex, notReady }) => css`
    animation-name: ${riseUp};
    animation-duration: 1000ms;
    animation-delay: calc(${sentenceIndex}*200ms);
    animation-fill-mode: forwards;
    display: block;
    transform: translateY(125%);
    ${notReady &&
    css`
      animation-play-state: paused;
    `}
  `
);
