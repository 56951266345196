import * as Styled from "./OxWaveText.styled";

import React, { useEffect, useState } from "react";

type TLine = string[] | { sentence: string[] };

type TText = TLine[];

type TProps = {
  text: TText;
  elementoverride: TText;
  animationInit: boolean;
};

export const OxWaveText = ({
  animationInit,
  text = [],
  elementoverride,
  ...props
}: SCProps<"div", TProps>): JSX.Element => {
  const sentenceIndex = -1;
  const [notReady, setNotReady] = useState(true);

  useEffect(() => {
    if (animationInit) {
      setNotReady(false);
    }
  }, [animationInit]);

  return (
    <Styled.Container {...props}>
      {text.map((line: TLine, i: number) => (
        <Styled.Line key={i} as={elementoverride}>
          {(line?.sentence || line || []).map((sentence: string, k: number) => (
            <Styled.Sentence
              key={k}
              as={"span"}
              sentenceIndex={sentenceIndex}
              notReady={notReady}
            >
              {sentence}
            </Styled.Sentence>
          ))}
        </Styled.Line>
      ))}
    </Styled.Container>
  );
};
