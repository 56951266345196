/**
 * This file must be .js as we can't import types
 * as importing types would then mean we can't export this file using modules.export,
 * which we need to do to convert the data on page creation, to save frontend processing
 */

/**
 *
 * @type {{asset?: {gatsbyImageData?: IGatsbyImageData}, childImageSharp?: {gatsbyImageData?: IGatsbyImageData}}}
 *
 * @return IGatsbyImageData | Record<string, never>
 */
const getGatsbyImageDataFromImage = (data) => {
  if (data.asset) {
    return data?.asset?.gatsbyImageData ?? {};
  }

  if (data.childImageSharp) {
    return data?.childImageSharp?.gatsbyImageData ?? {};
  }

  return {};
};

module.exports.getGatsbyImageDataFromImage = getGatsbyImageDataFromImage;

const convertFluidImages = (images) => {
  if (!images) return [];
  // We've possibly already converted this referenced object/array
  if (!(images.mobile_image && images.desktop_image)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    return images;
  }

  const imageArr = [
    {
      ...getGatsbyImageDataFromImage(images.mobile_image),
      media: `(max-width: 768px)`,
    },
    {
      ...getGatsbyImageDataFromImage(images.desktop_image),
      media: `(min-width: ${images.tablet_image ? "1024" : "769"}px)`,
    },
  ];

  if (images.tablet_image) {
    imageArr.push({
      ...getGatsbyImageDataFromImage(images.tablet_image),
      media: "(min-width: 769px) and (max-width: 1023px)",
    });
  }

  return imageArr;
};

/**
 * We need to iterate through each block settings and convert
 *  image data to a format usable by the component in gatsby
 *
 * @param block
 */
const convertBasedOnBlockType = (block) => {
  switch (block.identifier) {
    case "OxExperienceGrid": {
      const settings = block.settings;
      settings.items = settings?.items?.map((item) => ({
        ...item,
        image: getGatsbyImageDataFromImage(item.image),
      }));
      break;
    }

    case "OxBanner": {
      const settings = block.settings;
      settings.items = settings?.items?.map((item) => ({
        ...item,
        fluidImages: convertFluidImages(item.fluidImages),
      }));
      break;
    }

    case "OxFeatureBlock": {
      const settings = block.settings;
      settings.sliderData = settings?.sliderData?.map((item) => ({
        ...item,
        carouselData: {
          ...item.carouselData,
          fluidImages: convertFluidImages(item.carouselData?.fluidImages),
        },
      }));
      break;
    }

    case "OxFullBleedFeature": {
      const settings = block.settings;
      settings.fluidImages = convertFluidImages(settings.fluidImages);
      break;
    }

    case "OxMediaCarousel": {
      const settings = block.settings;
      settings.items = settings?.items?.map((item) => ({
        ...item,
        fluidImages: convertFluidImages(item.fluidImages),
      }));
      break;
    }

    case "OxQuoteSlider": {
      const settings = block.settings;
      settings.items = settings?.items?.map((item) => ({
        ...item,
        fluidImage: convertFluidImages(item.fluidImage),
      }));
      break;
    }

    case "OxCarousel": {
      const settings = block.settings;
      settings.items = settings?.items?.map((item) => ({
        ...item,
        fluidImages: item.fluidImages
          ? convertFluidImages(item.fluidImages)
          : undefined,
        fluidImage: item.fluidImage
          ? convertFluidImages(item.fluidImage)
          : undefined,
      }));
      break;
    }

    case "OxServiceSet": {
      const settings = block.settings;
      settings.items = settings?.items?.map((item) => ({
        ...item,
        fluidImage: convertFluidImages(item.fluidImage),
      }));
      break;
    }

    case "OxSplitBlock": {
      const settings = block.settings;
      settings.items = settings?.items?.map((item) => ({
        ...item,
        fluidImagesArray: item.fluidImagesArray?.map(convertFluidImages),
      }));
      break;
    }

    case "OxStory": {
      const settings = block.settings;
      settings.fluidImagesArray =
        settings.fluidImagesArray?.map(convertFluidImages);
      break;
    }

    case "OxRichStory": {
      const settings = block.settings;
      settings.fluidImagesArray =
        settings.fluidImagesArray?.map(convertFluidImages);
      break;
    }
  }

  return block;
};

const convertMeta = (meta) => {
  return {
    ...meta,
    image: meta.image?.asset.gatsbyImageData.images.fallback.url,
  };
};

module.exports.convert = (page) => {
  return {
    ...page,
    blocks: page.blocks?.map(convertBasedOnBlockType),
    meta: convertMeta(page.meta ?? {}),
  };
};
