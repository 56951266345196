import React, { Suspense } from "react";

import loadable from "@loadable/component";
import { TOxCoreVideoPlayerProps } from "./components/OxCoreVideoPlayer";
import { OxSpinner } from "src/components/OxSpinner";

const VideoPlayer = loadable(() => import("./components/OxCoreVideoPlayer"));

export type TOxVideoPlayerProps = TOxCoreVideoPlayerProps;

export const OxVideoPlayer = React.forwardRef<
  HTMLVideoElement,
  TOxVideoPlayerProps
>((props: SCProps<"div", TOxVideoPlayerProps>, ref): JSX.Element => {
  return (
    <>
      <Suspense fallback={<OxSpinner />}>
        <VideoPlayer {...props} ref={ref} />
      </Suspense>
    </>
  );
});
